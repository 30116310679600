import { LS_ORDER } from "@/components/v2/contexts/OrderContextNew/OrderContextNew";
import {
  Currency,
  CustomerCashAccountDocument,
  CustomerPaymentMethodsDocument,
  FetchOrderReceiptV2Document,
  GenericSourceForStoreDocument,
  MyActiveVouchersDocument,
  OrderDocument,
  SourceBusinessHoursDocument,
  SourceType,
  StoreSourceForLocationSelectorDocument,
} from "@/generated/requests/pos";
import {
  FetchStoreBySlugFullDocument,
  StoreByStoreIdDocument,
  StoreSourcesByStoreIdDocument,
} from "@/generated/requests/services";
import { useAPIQuery, usePOSQuery } from "@/lib/apollo-hooks";
import { fetchLocal } from "@/static/lib/util";

const POLL_INTERVAL = 600000; // 10 minutes in milliseconds

export const useGetOrder = () => {
  const orderId = fetchLocal<string>(LS_ORDER);
  const { data, loading, error, refetch, networkStatus } = usePOSQuery(OrderDocument, {
    variables: {
      orderOrReceiptId: orderId,
    },
    skip: !orderId,
    fetchPolicy: "cache-and-network",
    pollInterval: POLL_INTERVAL,
  });

  return { data, loading, error, refetch, networkStatus };
};

export const useGetStoreBySlug = ({ storeSlug }: { storeSlug: string }) => {
  const { data, loading, error } = useAPIQuery(FetchStoreBySlugFullDocument, {
    variables: {
      slug: storeSlug,
    },
    skip: !storeSlug,
    fetchPolicy: "cache-first",
    pollInterval: POLL_INTERVAL,
  });

  return { data: data?.stores?.storeForSlug, loading, error };
};

export const useGetSource = ({ storeId, type, timeSlot }: { storeId: string; type: SourceType; timeSlot: string }) => {
  const { data, loading, error } = usePOSQuery(GenericSourceForStoreDocument, {
    variables: {
      storeId: storeId,
      type: type,
      pickupDate: timeSlot,
    },
    skip: !type || !storeId,
    fetchPolicy: "cache-first",
    pollInterval: POLL_INTERVAL,
  });

  return { data, loading, error };
};

export const useGetSourceAllowedDaysToOrder = ({
  storeId,
  sourceType,
}: {
  storeId: string;
  sourceType: SourceType;
}) => {
  const { data, loading, error } = usePOSQuery(StoreSourceForLocationSelectorDocument, {
    variables: {
      storeId: storeId,
      type: sourceType,
    },
    skip: !storeId || !sourceType,
    pollInterval: POLL_INTERVAL,
  });

  return { data, loading, error };
};

export const useGetStoreById = ({ storeId }: { storeId: string }) => {
  const { data, loading, error } = useAPIQuery(StoreByStoreIdDocument, {
    variables: {
      storeId: storeId,
    },
    skip: !storeId,
    pollInterval: POLL_INTERVAL,
  });

  return { data: data?.stores?.publicStoreInfo, loading, error };
};

export const useGetStoreSources = ({ storeId }: { storeId: string }) => {
  const { data, loading, error } = useAPIQuery(StoreSourcesByStoreIdDocument, {
    variables: {
      storeId: storeId,
    },
    skip: !storeId,
    pollInterval: POLL_INTERVAL,
  });

  return { data: data?.stores.store, loading, error };
};

export const useGetCustomerCashAccount = ({ customerId, currency }: { customerId: string; currency?: string }) => {
  const { data, loading, error } = usePOSQuery(CustomerCashAccountDocument, {
    variables: {
      currency: currency || Currency.Usd,
    },
    skip: !customerId,
  });

  return { data, loading, error };
};

export const useGetCustomerPaymentMethods = ({
  customerId,
  stripeRegion,
}: {
  customerId: string;
  stripeRegion: string;
}) => {
  const { data, loading, error, refetch } = usePOSQuery(CustomerPaymentMethodsDocument, {
    variables: {
      stripeRegion: stripeRegion || "US",
    },
    skip: !stripeRegion || !customerId,
  });

  return { data, loading, error, refetch };
};

export const useGetVouchers = ({ customerId }: { customerId: string }) => {
  const { data, loading, error } = usePOSQuery(MyActiveVouchersDocument, {
    skip: !customerId,
    fetchPolicy: "cache-first",
  });

  return { data, loading, error };
};

export const useGetSourceBusinessHours = ({
  storeId,
  type,
  selectedDate,
}: {
  storeId: string;
  type: SourceType;
  selectedDate: string;
}) => {
  const { data, loading, error } = usePOSQuery(SourceBusinessHoursDocument, {
    variables: {
      storeId: storeId,
      type: type,
      selectedDate: selectedDate,
    },
    skip: !storeId || !type || !selectedDate,
    pollInterval: POLL_INTERVAL,
  });

  return { data, loading, error };
};

export const useGetReceipt = ({ receiptId }: { receiptId: string }) => {
  const { data, loading, error, refetch } = usePOSQuery(FetchOrderReceiptV2Document, {
    variables: { orderId: receiptId },
    skip: !receiptId,
    // because the receipt can change when a customer arrives, or their order is updated
    fetchPolicy: "no-cache",
  });

  return { data, loading, error, refetch };
};
